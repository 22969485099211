// 答题中心
import request from '../utils/request';
// 获取
export async function getQuestionList(current) {
    try {
        return await request({
            url: `/interviewExperienceDetailExplain/wait?current=${current}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}


export async function acceptQuestion(id) {
    try {
        return await request({
            url: `/interviewExperienceDetailExplain/solve/${id}`,
            method: 'put',
        });
    } catch (error) {
        return {};
    }

}

export async function getSolvingList(current) {
    try {
        return await request({
            url: `/interviewExperienceDetailExplain/solving?current=${current}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

export async function getMyQuestionHistoryList(current) {
    try {
        return await request({
            url: `/interviewExperienceDetailExplain/history?current=${current}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
export async function getQuestionDetail(id) {
    try {
        return await request({
            url: `/interviewExperienceDetailExplain/${id}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}


export async function answerQuestion(data) {
    try {
        return await request({
            url: `/interviewExperienceDetailExplain`,
            method: 'put',
            data
        });
    } catch (error) {
        return {};
    }
}