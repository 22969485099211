<template>
  <!-- 我的问题 -->
  <div id="questionList">
    <div class="questions" v-for="(question, index) in listData" :key="index">
      <div class="descriptions">
        <div class="title">{{ question.question }}</div>
        <div class="acceptButton">
          <el-button round class="acceptBtn" @click="answerQuestion(question)"
            >回答题目</el-button
          >
        </div>
      </div>

      <div class="pictures" style="margin-top: 12px; width: 90%">
        <el-image
          v-for="(image, index) in question.pictures"
          style="width: 100px; height: 100px; margin-right: 12px"
          :src="image"
          :key="index"
          @click="
            showIndex = index;
            showViewer = true;
            currentPictures = question.pictures;
          "
        />
      </div>
      <div class="duration">有效期至：{{ question.lostEfficacyTime }}</div>
    </div>
    <el-image-viewer
      v-if="showViewer"
      :initial-index="showIndex"
      :url-list="currentPictures"
      :on-close="
        () => {
          showViewer = false;
        }
      "
    />
    <el-pagination
      v-if="total > 10"
      style="padding: 20px; text-align: center"
      :current-page.sync="currentPage"
      :total="total"
      layout="prev, pager, next"
      @current-change="getSolvingList"
    >
    </el-pagination>
  </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { getSolvingList } from "@/service/answerCenter";
export default {
  name: "list",
  components: { ElImageViewer },

  data() {
    return {
      listData: [],
      total: 0,
      currentPage: 1,
      showViewer: false,
      currentPictures: [],
      showIndex: 0,
      currentQuestion: {},
      detailVisible: false,
    };
  },
  mounted() {
    this.getSolvingList();
  },
  methods: {
    getSolvingList(page) {
      if (page) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
      getSolvingList(this.currentPage).then((res) => {
        if (res.success) {
          this.listData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    answerQuestion(question) {
      this.$router.push("/answerCenter/answerQuestion?id=" + question.id);
    },
  },
};
</script>
<style scoped lang="scss">
.questions {
  padding: 30px 80px;
  margin-bottom: 8px;
  background: #fff;
  .descriptions {
    display: flex;
    flex-wrap: nowrap;
  }
  .title {
    width: 90%;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 18px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .acceptButton {
    width: 10%;
    .acceptBtn {
      border: 1px solid rgba(0, 117, 246, 1);
      color: rgba(0, 117, 246, 1);
      padding: 10px 24px;
    }
  }
}
::v-deep .el-dialog {
  border-radius: 12px;
  padding: 12px 60px;
}
::v-deep .el-dialog__header {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 3px;
    height: 20px;

    background: #0084ff;
    border-radius: 2px;
    left: 10px;
    top: 22px;
  }
}
::v-deep .el-dialog__headerbtn {
  font-size: 18px;
  right: -20px;
  top: 10px;
}
.duration {
  color: #0075f6;
}
</style>